export function transformToBrandUser(user: any) {
  const {
    accountId: { id: accountId },
    brandId,
    brandName,
    userId: { id: userId },
    programId: { id: programId },
  } = user;
  return {
    ...user,
    brandUserId: `${accountId}_${brandId}_${programId}_${userId}`,
    userId: {
      ...user.userId,
      programs: [
        {
          ...user.programId,
          accountId: user.accountId.id,
          accountName: user.accountId.name,
          brands: [{ id: brandId, name: brandName }],
        },
      ],
    },
  };
}

export interface UsersOptions {
  account?: string;
  brand?: string;
  last?: any;
}
